@import "../../styles/variables/variables";
@import "../../styles/variables/breakpoints";

.item-modal__backdrop {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 13.09%, rgba(0, 0, 0, 0) 52.31%), rgba(0, 0, 0, 0.6) !important;

  &.show {
    opacity: 1;
  }
}

.item-modal {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */

  .modal-dialog {
    max-width: 1410px;
    margin: 0 auto;
    height: 100vh;
  }

  .modal-content {
    background-color: transparent;
    border: none;
    border-radius: 0;
    height: 100vh;
  }

  .item-modal__slider-container {
    position: relative;
    height: 100vh;

    .item-modal__slider-box {
      height: calc(100vh - 100px ); // 100 - врехняя панель
      max-width: 1200px;
      //min-height: 300px;
      width: 85%;

      position: relative;
      margin: 0 auto;
      padding: 20px 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      @media screen and (max-height: 875px) and (orientation: landscape) {
        padding: 10px 0;
      }

      @media screen and (max-height: 1000px) {
        height: calc(100vh - 50px); // 50- врехняя панель
      }

      @include breakpoint($lt-xl) {
        width: 95%;
      }
    }
  }

  .item-modal__btn {
    position: absolute;
    background-color: #1E1E1E;
    border-radius: $border-radius-big;
    width: 48px;
    height: 48px;
    top: calc(50% - 24px);
    color: var(--theme-sharing-font-secondary);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: .8;
    cursor: pointer;

    svg {
      width: 24px;
      height: 24px;
    }

    &:hover {
      opacity: 1;
    }
  }

  .item-modal__btn-next-slider {
    @extend .item-modal__btn;
    right: - 80px;

    @include breakpoint($lt-xl) {
      right: 20px;
    }
  }

  .item-modal__btn-preview-slider {
    @extend .item-modal__btn;
    left: - 80px;

    @include breakpoint($lt-xl) {
      left: 20px;
    }
  }


  .item-modal__animation-box-enter {
    opacity: 0;
    margin-right: -50vw;
    transform: scale(.75);
  }
  .item-modal__animation-box-exit {
    opacity: 1;
    margin-right: 0;
  }
  .item-modal__animation-box-enter-active {
    opacity: 1;
    margin-right: 0;
    transform: scale(1);
  }
  .item-modal__animation-box-exit-active {
    opacity: 0;
    margin-right: 50vw;
    transform: scale(.75);
  }
  .item-modal__animation-box-enter-active,
  .item-modal__animation-box-exit-active {
    transition: opacity 150ms,
                transform 150ms,
                margin-right 150ms;
  }

  .item-modal__animation-box-revert-enter {
    opacity: 0;
    margin-right: 50vw;
    transform: scale(.75);
  }
  .item-modal__animation-box-revert-exit {
    opacity: 1;
    margin-right: 0;
  }
  .item-modal__animation-box-revert-enter-active {
    opacity: 1;
    margin-right: 0;
    transform: scale(1);
  }
  .item-modal__animation-box-revert-exit-active {
    opacity: 0;
    margin-right: -50vw;
    transform: scale(.75);
  }
  .item-modal__animation-box-revert-enter-active,
  .item-modal__animation-box-revert-exit-active {
    transition: opacity 150ms,
    transform 150ms,
    margin-right 150ms;
  }
}
