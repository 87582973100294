@import "../../../styles/variables/variables";
@import "../../../styles/variables/breakpoints";

.quota-box {
  width: 100%;

  .quota-box__info {
    color: var(--theme-font-secondary);
  }

  .progress {
    margin-top: .125rem;
    background-color: var(--theme-button);
  }
}
