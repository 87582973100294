@import "../../styles/variables/variables";
@import "../../styles/variables/breakpoints";

.result-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 15px;
  color: var(--theme-font-primary);
  cursor: pointer;
  text-decoration: none;


  &:first-child {
    padding-top: 15px;

    @include breakpoint($lt-lg) {
      padding-top: 30px;
    }
  }

  &:hover {
    color: var(--theme-font-primary);
    text-decoration: none;

    .result-item__info-text {
      text-decoration: underline;
    }
  }

  .result-item__date {
    color: var(--theme-font-secondary);
    font-size: .75rem;
  }

  .result-item__info {
    display: flex;
    align-items: center;
    width: 61%;
  }

  .result-item__info-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 15px;
  }

  .result-item__icon-box {
    width: 28px;
    min-width: 28px;
    height: 28px;
    margin-right: 10px;

    svg {
      width: 100%;

      path {
        fill: var(--theme-font-tertiary);
      }
    }


  }
}

.light .result-item {
  .result-item__icon-box {
    svg path {
      fill: var(--theme-font-tertiary);
    }
  }
}

