@import "../../styles/variables/variables";
@import "../../styles/variables/breakpoints";

.top-search__wrap {
  .top-search__mobile-icon {
    display: none;

    @include breakpoint($lt-lg) {
      display: block;
      width: 20px;
      height: 20px;
      background-image: url("/static/public/icons/search-blue.svg");
      background-size: cover;
      cursor: pointer;
    }
  }
}

.top-search {
  max-height: 48px;

  @include breakpoint($lt-lg) {
    // flex-grow: 1;
    display: flex;
    justify-content: flex-end;
  }

  .top-search__bg {
    position: absolute;
    display: none;
    z-index: 1;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  .top-search__container {
    z-index: 2;
    @include breakpoint($lt-lg) {
      position: fixed;
      left: 0;
      right: 0;
      top: - $header-height-mobile - 90px;
      min-height: $header-height-mobile + 90px;
      background-color: var(--theme-modals-ui-element-bg);
      padding: 100px + $default-padding $default-padding $default-padding $default-padding;
      transition: top .2s linear;
      display: flex;
      align-items: center;
    }
  }

  .top-search__search-box {
    min-width: 360px;
    max-width: 360px;
    position: relative;
    background-color: var(--theme-button);
    border-radius: $border-radius;
    transition: all .1s ease-in-out;

    &:after {
      content: '';
      background-image: url("/static/public/icons/search.svg");
      width: 20px;
      height: 20px;
      position: absolute;
      top: 14px;
      left: 15px
    }

    @include breakpoint($lt-lg) {
      width: 100%;
      min-width: auto;
      background-color: transparent;
      border-radius: 0;
      border: none;
      max-width: none;
    }
  }

  .top-search__input-box {
    @include breakpoint($lt-lg) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .top-search__input-wrap {
    position: relative;

    @include breakpoint($lt-lg) {
      width: calc(100% - 20px - 20px - 20px);
    }

    .top-search__input-clear {
      cursor: pointer;
      width: 20px;
      height: 20px;
      background-image: url("/static/public/icons/search-clear.svg");
      opacity: 0;
      border-radius: 50%;
      position: absolute;
      top: calc(50% - 10px);
      right: 20px;
      visibility: hidden;
      transition: opacity .2s linear;

      &--show {
        visibility: visible;
        opacity: .8;

        @include breakpoint($lt-lg) {
          display: none;
        }

        &:hover {
          opacity: 1;
        }
      }
    }
  }

  .top-search__input {
    color: var(--theme-font-primary);
    width: 100%;
    background-color: transparent;
    border: none;
    border-radius: $border-radius-big;
    padding-left: 50px;
    padding-right: 50px;
    font-weight: 300;

    &::placeholder {
      color: var(--theme-font-tertiary);
      font-weight: 300;
    }

    &:focus {
      border: none;
      background-color: transparent;
      box-shadow: none;
    }

    @include breakpoint($lt-lg) {
      background-color: var(--theme-button);
      border-radius: $border-radius-big;
      border: none;
      transition: all .2s linear;

    }
  }

  .top-search__result-box {
    display: none;
    //max-height: calc(100vh - 50px - 30px);
    //overflow-y: scroll;
  }

  .top-search__mobile-cross {
    display: none;

    @include breakpoint($lt-lg) {
      display: block;
      width: 20px;
      height: 20px;
      margin-right: 10px;
      background-image: url("/static/public/icons/cross-blue.svg");
      background-size: cover;
      cursor: pointer;
    }
  }

  &--focus {
    @include breakpoint($lt-lg) {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(0, 0, 0, 0.6);
      height: 100vh;
      max-height: 100vh;
      z-index: 10;
    }

    .top-search__container {
      @include breakpoint($lt-lg) {
        top: - 100px;
      }
    }

    .top-search__bg {
      @include breakpoint($lt-lg) {
        display: block;
      }
    }


    .top-search__search-box {
      background-color: var(--theme-modals-ui-element-bg);
      box-shadow: 2px 2px 8px var(--theme-box-shadow);
      z-index: 10;

      @include breakpoint($lt-lg) {
        box-shadow: none;
      }
    }

    .top-search__input {
      @include breakpoint($lt-lg) {
        padding-right: 1rem;

        &:focus {
          background-color: var(--theme-modals-ui-element-bg);
          box-shadow: 2px 2px 8px var(--theme-box-shadow);
          //border: 1px solid #ced4da;
        }
      }
    }

    .top-search__result-box {
      display: block;
    }

    .top-search__bg {
      display: block;
    }
  }
}
