@import "../../../styles/variables/variables";
@import "../../../styles/variables/breakpoints";

.album-hints__wrap {
  height: 138px;
  overflow: hidden;
  margin-bottom: 10px;

}
.album-hints__box {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
}

.album-hints__item {
  display: flex;
  align-items: center;
  white-space: nowrap;
  background-color: var(--theme-album-hints);
  border-radius: $border-radius-sm;
  padding: 0 12px;
  height: 38px;
  margin: 0 8px 8px 0;
  font-size: .9rem;
  cursor: pointer;

  &:hover {
    background-color: var(--theme-primary-color);
    color: var(--white)
  }
}

.album-hints__item-skeleton {
  border-radius: $border-radius-sm;
  height: 38px;
  margin: 0 8px 8px 0;
}
