@import "../../../styles/variables/variables";
@import "../../../styles/variables/breakpoints";

.theme-switcher {

  .react-switch-bg {
    background-color: var(--theme-button) !important;

    div {
      width: 20px !important;
      height: 20px !important;
      top: 4px !important;

      &:first-child {
        position: absolute !important;
        left: 5px !important;
      }

      &:last-child {
        position: absolute !important;
        right: 5px !important;

      }
    }

    svg {
      vertical-align: top;
    }
  }

  .react-switch-handle {
    background-color: var(--theme-modals-ui-element-bg) !important;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.05),
                0px 3px 1px rgba(0, 0, 0, 0.0510643),
                0px 2px 2px rgba(0, 0, 0, 0.1),
                0px 3px 3px rgba(0, 0, 0, 0.05) !important;
  }
}
