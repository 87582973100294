@import "../../styles/variables/variables";
@import "../../styles/variables/breakpoints";

.main-screen {
  @include breakpoint($gt-sm) {
    min-height: calc(100vh - 155px - 74px); //высота headers
  }

  .main-screen__wrap {
    padding-top: 30px;
    padding-left: $distance-between-sidebar-and-container;
    max-width: $max-container-width-with-sidebar + $distance-between-sidebar-and-container;

    @include breakpoint($lt-lg) {
      padding-left: 0;
    }
  }

  //-webkit-touch-callout: none; /* iOS Safari */
  //-webkit-user-select: none; /* Safari */
  //-khtml-user-select: none; /* Konqueror HTML */
  //-moz-user-select: none; /* Old versions of Firefox */
  //-ms-user-select: none; /* Internet Explorer/Edge */
  //user-select: none; /* Non-prefixed version, currently
  //                                supported by Chrome, Edge, Opera and Firefox */
  .main-screen__title-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .main-screen__section-title {
    padding: 30px 0;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 1.3rem;
    color: var(--theme-font-secondary);

    @include breakpoint($lt-md) {
      padding: 20px 0 20px 0;
      font-size: 1.2rem;
    }
  }

  .main-screen__mobile-sort {
    display: flex;
    justify-content: flex-end;

    padding: 1rem 0 .5rem 0;

    @include breakpoint($gt-md) {
      display: none;
    }
  }
}

.mouse-border-select {
  position: absolute;
  display: none;

  top: 400px;
  left: 400px;
  height: 200px;
  width: 300px;
  background-color: var(--theme-font-tertiary);
  border: 1px solid var(--theme-font-primary);
  opacity: .3;
  &--open {
    display: block;
  }

}
