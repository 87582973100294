@import "../../styles/variables/variables";
@import "../../styles/variables/breakpoints";

.filter-picker {
  margin-right: 16px;

  .filter-picker__btn {
    position: relative;

    .badge {
      position: absolute;
      top: -6px;
      right: -6px;
      font-weight: normal;
    }
  }

  .spinner-box {
    padding: .5rem;
  }

  .dropdown-menu__box {
    min-width: 270px;
  }

  .dropdown-menu__items {
    overflow-y: auto;
    max-height: 380px;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      -webkit-border-radius: 4px;
      border-radius: 4px;
      background: var(--theme-button-hover);
    }

    @media screen and (max-height: 525px) {
      max-height: calc(100vh - 15vh - 80px - 65px);
    }
  }

  .filter-picker__header {
    color: var(--theme-font-secondary);
    text-transform: uppercase;
  }

  .filter-picker__icon {
    width: 24px;
    height: 24px;
    margin-right: 10px;
    color: var(--theme-font-tertiary);
  }

  .filter-picker__item-wrap {
    display: flex;
    width: calc(100% - 24px);
  }

  .filter-picker__item {
    justify-content: space-between;
    &:hover {
      .filter-picker__item-name {
        text-decoration: underline;
      }
      .filter-picker__icon {
        color: var(--theme-font-primary);
      }
    }

    &:after {
      content: '';
      width: 24px;
      height: 24px;
      background-image: url("/static/public/icons/check-icon.svg");
      background-repeat: no-repeat;
      background-size: 100%;
      opacity: 0;
      transition: opacity .1s linear;
    }

    &--active {
      &:after {
        opacity: 1;
      }
    }
  }

  .filter-picker__item-info {
    display: flex;
    flex-direction: column;
    max-width: calc(100% - 34px);
    color: var(--theme-font-primary)
  }

  .filter-picker__item-sn {
    color: var(--theme-font-secondary);
    font-size: .7rem;
    text-decoration: none;
  }

  .filter-picker__footer-btn {
    width: 100%;
  }
}
