@import "../../../styles/variables/variables";
@import "../../../styles/variables/breakpoints";

.menu-group {
  margin-bottom: 16px;

  .menu-item {
    border-radius: 0;
    margin-bottom: 0;

    &:first-child {
      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;
    }

    &:last-child {
      border-bottom-left-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
    }
  }
}
