@import "../../../../styles/variables/variables";
@import "../../../../styles/variables/breakpoints";

$add-file-to-album-item-margin: 12px;

.add-file-to-album-item {
  margin-right: $add-file-to-album-item-margin;
  width: calc((100% - 2 * #{$add-file-to-album-item-margin}) / 3 );
  margin-bottom: 12px;
  cursor: pointer;

  &:hover {
    .add-file-to-album-item__title {
      text-decoration: underline;

    }

    .add-file-to-album-item__preview {
      transform: scale(1.05);
    }
  }

  @include breakpoint($gt-xs) {
    &:nth-child(3n) {
      margin-right: 0;
    }
  }

  @include breakpoint($lt-sm) {
    width: calc((100% - #{$add-file-to-album-item-margin}) / 2 );

    &:nth-child(2n) {
      margin-right: 0;
    }
  }

  .add-file-to-album-item__preview {
    border-radius: $border-radius;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-image: url("/static/public/images/album-placeholder.svg");
    transition: transform .3s;

    &:before {
      content: "";
      padding-top: 100%;
      display: block;
    }
  }

  .add-file-to-album-item__title {
    padding-left: 8px;
    padding-top: 6px;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
