@import "../../../styles/variables/variables";
@import "../../../styles/variables/breakpoints";

.search-input-wrap {
  background-color: var(--theme-text-field);
  border-radius: $border-radius-big;
  border: 1px solid var(--theme-text-field);
  transition: all .1s ease-in-out;
  position: relative;
  padding-left: 30px;

  &:after {
    content: '';
    background-image: url("/static/public/icons/search.svg");
    width: 20px;
    height: 20px;
    position: absolute;
    top: 14px;
    left: 15px
  }

  &--focus {
    background-color: var(--theme-modals-ui-element-bg);
  }

  .search-input {
    background-color: transparent;
    border: none;
    color: var(--theme-font-primary);
    font-weight: 300;

    &::placeholder {
      color: var(--theme-font-tertiary);
    }

    &:focus {
      border: none;
      background-color: transparent;
      box-shadow: none;
    }
  }
}

