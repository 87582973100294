@import "../../styles/variables/variables";
@import "../../styles/variables/breakpoints";

.sidebar__new-album-btn {
  color: var(--blue);

  &:hover {
    color: var(--blue);
  }
}
