.Toastify__toast-container {
  .Toastify__toast {
    cursor: default;
    border-radius: $border-radius !important;
  }

  .Toastify__toast-body {
    text-align: center;
  }

  &.Toastify__toast-container--top-center {
    width: 551px;
    max-width: 551px;
    top: 2rem;

    @include breakpoint($lt-md) {
      width: 90vw;
      left: 50%;
      transform: translateX(-50%);
      top: 1rem;
    }

    .Toastify__close-button {
      display: none;
    }

    .Toastify__toast--info {
      background-color: var(--theme-primary-color);
    }

    .Toastify__toast {
      margin-bottom: .5rem;
      display: flex;
      justify-content: center;
    }
  }
}
