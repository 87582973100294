@import "../../../styles/variables/variables";
@import "../../../styles/variables/breakpoints";

.spinner-box {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 3rem;
  padding-bottom: 1.5rem;
}
