@import "../../../../styles/variables/variables";
@import "../../../../styles/variables/breakpoints";

.file-share-modal {
  .form-check {
    padding-left: 1.75rem;
    display: flex;
    align-items: center;
  }

  .form-check-input {
    width: 22px;
    height: 22px;
    margin-left: -1.75rem;
    margin-top: 0;
  }

  .file-share-modal__link-box {
    overflow: hidden;
    color: gray;
    position: relative;
    padding-left: 1.75rem;
    font-size: .9rem;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .file-share-modal__link-icon {
    position: absolute;
    left: 0;
    top: calc(50% - 11px);
    width: 22px;
    height: 22px;
    color: var(--theme-primary-color);
  }

  .file-share-modal__hidden-link {
    display: none;
  }
}
