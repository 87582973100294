@import "../../../styles/variables/variables";
@import "../../../styles/variables/breakpoints";

.placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 6rem;

  @include breakpoint($lt-md) {
    padding-top: 3rem;
  }

  .placeholder__logo {
    path {
      fill: var(--theme-button-hover); //todo нет этого цвета (разобраться)
    }

    @include breakpoint($lt-md) {
      width: 150px;
    }
  }

  .placeholder__text {
    color: var(--theme-button-hover);
    text-align: center;
    font-size: 3rem;
    font-weight: 200;
    margin-top: 1rem;

    @include breakpoint($lt-md) {
      font-size: 2rem;
      margin-top: .5rem;
    }
  }
}
