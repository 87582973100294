@import "../../styles/variables/variables";
@import "../../styles/variables/breakpoints";

.selection-bar {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */

  position: fixed;
  padding: 10px 15px;
  left: 0;
  right: 0;
  height: $header-height-mobile;
  top: - $header-height-mobile;
  background-color: var(--theme-background);
  opacity: 0;
  box-shadow: 2px 2px 8px var(--theme-box-shadow);

  transition: opacity .3s linear,
              top .3s linear;

  display: flex;
  justify-content: space-between;
  align-items: center;

  &--show {
    opacity: 1;
    top: 0;
  }

  .selection-bar__left {
    display: flex;
    align-items: center;
  }

  .selection-bar__close-icon {
    width: 26px;
    height: 26px;
    color: var(--theme-primary-color);
    margin-right: 1rem;
  }

  .selection-bar__context {
    svg {
      width: 22px;
      height: 22px;
      color: var(--theme-primary-color);
    }
  }

  .selection-bar__count {
    color: var(--theme-font-secondary);
    font-size: .9rem;
  }
}
