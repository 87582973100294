@import "../../../styles/variables/variables";
@import "../../../styles/variables/breakpoints";

.connected-accounts {
  .connected-accounts__title {
    font-weight: bold;
    margin-bottom: 1rem;
  }

  .connected-accounts__description {
    display: inline-block;
    font-size: .85rem;
    color: var(--theme-font-secondary);
    margin-top: 10px;
  }
}
