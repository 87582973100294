@import "../../../styles/variables/variables";
@import "../../../styles/variables/breakpoints";

.edit-profile-modal {

}

.edit-profile-modal__avatar-box {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
