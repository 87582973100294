@import "../../styles/variables/variables";
@import "../../styles/variables/breakpoints";

.item-modal-control {
  height: 100px;
  max-width: 1200px;

  width: 85%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--theme-background);

  @media screen and (max-height: 1000px) {
    height: 50px;
  }

  .item-modal-control__icon,
  .item-modal-control__ctx,
  .item-modal-control__close {
    width: 26px;
    min-width: 26px;
    height: 26px;
    cursor: pointer;
    color: var(--theme-viewer-ui-el);
    transition: color 100ms linear;

    svg {
      width: 100%;
      height: 100%;
    }

    &:hover {
      color: var(--theme-viewer-ui-el-hover);
    }
  }

  .item-modal-control__close,
  .item-modal-control__icon {
    margin-right: 1rem;
  }

  .item-modal-control__icon {
    @include breakpoint($lt-md) {
      display: none;
    }
  }

  .item-modal-control__ctx-box {
    display: flex;
    justify-content: flex-end;
  }

  .item-modal-control__name {
    max-width: 60%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--theme-viewer-ui-el)
  }
}
