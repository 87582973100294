@import "../../../styles/variables/variables";
@import "../../../styles/variables/breakpoints";

.skeleton-line {
  display: inline-block;
  height: 15px;
  width: 100%;
  min-width: 40px;
  background: linear-gradient(-90deg, var(--theme-button) 0%, var(--theme-button-hover) 50%, var(--theme-button) 100%);
  background-size: 400% 400%;
  animation: skeletonPulse 2s ease-in-out infinite;

  @keyframes skeletonPulse {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: -135% 0%;
    }
  }

  border-radius: 2px;
}
