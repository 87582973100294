@import "../../styles/variables/variables";
@import "../../styles/variables/breakpoints";

.static-text {
  margin: 1.5rem 0 4rem 0;
  color: var(--theme-font-primary);

  .static-text__title {
    font-size: 1.4rem;
    font-weight: bold;

    @include breakpoint($lt-md) {
      font-size: 1.2rem;
    }
  }
}

.static-text-block {
  .static-text-block__block {
    font-size: 1.2rem;
    font-weight: bold;
    margin: 1.5rem 0;

    @include breakpoint($lt-md) {
      font-size: 1rem;
    }
  }

  &--without-margin {
    p {
      margin-bottom: 0;
    }
  }
}
