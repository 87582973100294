@import "../../../styles/variables/variables";
@import "../../../styles/variables/breakpoints";

.edit-profile-form {
  .btn {
    display: block;
    margin: 0 auto;
  }

  label {
    color: var(--theme-font-secondary)
  }
}
.edit-profile-form__connected-account {
  margin-top: 2.5rem;
  margin-bottom: 1.5rem;
}
