@import "../../styles/variables/variables";
@import "../../styles/variables/breakpoints";

.sort-picker {
  .dropdown-menu__item {
    padding: 5px 15px;
    border-bottom: none;

    &:last-child {
      padding-bottom: 15px;
    }

    &:first-child {
      padding-top: 15px;
    }
  }

  .dropdown-menu__box {
    min-width: 180px;
  }

  .sort-picker__item {
    width: 100%;
    cursor: pointer;

    div:first-child {
      color: var(--theme-font-primary);
    }

    div:last-child {
      color: var(--theme-font-secondary);
      font-size: .9rem;
    }
  }

  .sort-picker__btn {
    color: var(--theme-font-secondary);
    cursor: pointer;
    font-size: .9rem;
    padding-right: 17px;
    position: relative;

    &:after {
      position: absolute;
      right: 0;
      top: calc(50% - 2px);
      content: '';
      width: 0;
      height: 0;
      border: 5px solid transparent;
      border-top: 5px solid var(--theme-font-secondary);
    }
  }
}
