@import "../../../styles/variables/variables";
@import "../../../styles/variables/breakpoints";

.edit-profile-btn {
  display: block;
  padding: 0.125rem 0;
  margin-top: 1rem;
  cursor: pointer;
  color: var(--theme-primary-color);

  &:hover {
    text-decoration: underline;
    color: var(--theme-primary-color-hover);
  }
}
