@import "../../styles/variables/variables";
@import "../../styles/variables/breakpoints";

.main-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 25px;
  border-bottom: 1px solid var(--theme-border);
  margin-left: $distance-between-sidebar-and-container;
  max-width: $max-container-width-with-sidebar;

  @include breakpoint($lt-lg) {
    margin-left: 0;
  }

  &.main-header__full {
    margin-left: 0;
    max-width: none;

    .main-header__left-box {
      max-width: 100%;
    }
  }

  .main-header__field-name {
    font-size: 2rem;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    color: var(--theme-font-primary);

    &:hover {
      text-decoration: none;
    }

    @include breakpoint($lt-md) {
      margin-right: .5rem;
      font-size: 1.4rem;
    }
  }

  .main-header__left-box {
    max-width: calc(100% - 112px - 15px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .main-header__right-box {
    display: flex;
    align-items: center;
  }

  .main-header__right-box {
    @include breakpoint($lt-md) {
      margin-left: .5rem;
    }
  }
}
