@import "../../../styles/variables/variables";
@import "../../../styles/variables/breakpoints";

.albums-list-item {
  margin-bottom: $custom-row-padding * 2;
  transition: opacity 0.1s linear;

  .albums-list-item__wrap {
    background-color: var(--theme-background);
    cursor: pointer;
    position: relative;
    border: 2px solid transparent;
    border-radius: $border-radius;
    overflow: hidden;

    -webkit-transition: -webkit-transform .15s linear;
    -moz-transition: transform .15s linear;
    -o-transition: transform .15s linear;
    transition: transform .15s linear;

    &[data-item-selected='true'] {
      background-color: var(--theme-select-background);
      border-color: var(--theme-primary-color);
    }

    &:before {
      content: "";
      padding-top: 75%;
      display: block;
    }
  }

  .albums-list-item__content {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .albums-list-item__icon {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.7) 100%);;
    }
  }

  .albums-list-item__title {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 8px 12px;
    color: var(--theme-always-white-font);
    display: flex;
    align-items: center;
    flex-wrap: nowrap;

    @include breakpoint($lt-md) {
      padding-right: 21px;
    }
  }

  .albums-list-item__mobile-context {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 25px;
    height: 25px;
    bottom: 3px;
    right: 0;
  }

  .albums-list-item__title-icon {
    width: 17px;
    min-width: 17px;
    height: 17px;
    color: var(--theme-always-white-font);
    margin-right: 5px;
  }


  .albums-list-item__title-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: .8rem;
  }

  .albums-list-item__icon-skeleton {
    height: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
