@import "../../../styles/variables/variables";
@import "../../../styles/variables/breakpoints";

.video-viewer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  video {
    width: 100%;
    //height: 100%;
    max-width: 1024px;
    max-height: 768px;

    &:focus {
      outline: none;
    }

    @media screen and (max-height: 875px) and (orientation: landscape) {
      height: calc(100vh - 130px);
      width: calc((100vh - 130px) * 1.333)
    }
  }
}
