@import "../../../styles/variables/variables";
@import "../../../styles/variables/breakpoints";

.devices-list__wrap-item {
  margin-bottom: 30px;
}

.devices-list__item {
  position: relative;
  background-color: var(--theme-button);
  height: 100%;
  border-radius: $border-radius-big;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--theme-font-primary);

  &:hover {
    background-color: var(--theme-button-hover);
    cursor: pointer;
  }

  .devices-list__item-has-update {
    background: var(--blue);
    color: var(--white);
    margin: 0 auto;
    position: absolute;
    top: -10px;
    font-size: .825rem;
    line-height: 1.35;
    font-weight: 600;
    padding:  7px 12px 6px 34px;
    border-radius: 24px;

    &:before {
      content: "";
      position: absolute;
      left: 10px;
      top: calc(50% - 9px);
      width: 18px;
      height: 18px;
      background-image: url("/static/public/icons/alert-white.svg");
    }
  }

  .devices-list__item-img {
    width: 85%;
    margin: 1rem 0;
  }

  h3 {
    font-size: 1.5rem;
    font-weight: 700;
    text-align: center;
    margin-bottom: 0;
  }

  .devices-list__item-sku {
    color: var(--theme-font-secondary);
  }
}
