@import "../../../styles/variables/variables";
@import "../../../styles/variables/breakpoints";

.context-menu {
  position: fixed;
  z-index: 1500;

  .context-menu-block {
    width: 100%;
    background-color: var(--theme-modals-ui-element-bg);
    border-radius: $border-radius-big;
    box-shadow: 2px 2px 8px var(--theme-box-shadow);
    transition: transform 0.3s ease-out;
  }

  .context-menu__header {
    display: none;
  }

  &.context-menu--mobile {
    top: 0 !important;
    left: 0 !important;
    height: 120vh !important;
    width: 100vw !important;
    background-color: rgba(0, 0, 0, 0.5);

    .context-menu-block {
      position: fixed;
      bottom: -500px;
      transition: bottom 0.2s ease-out;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      padding-bottom: 15vh;

      @media screen and (max-height: 450px) {
        max-height: 100vh;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        overflow: hidden;
      }
    }

    &.context-menu--mobile-show {
      .context-menu-block {
        bottom: - 15vh;
      }
    }

    .context-menu__header {
      display: flex;
      align-items: center;
      padding: 20px 15px;
      border-bottom: 1px solid var(--theme-button);
    }

    .context-menu__icon {
      display: block;
      svg {
        width: 24px;
        height: 24px;
        margin-right: 10px;
        color: var(--theme-font-tertiary);
      }
    }
  }

  .context-menu__icon {
    display: none;
  }

  .context-menu__header-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--theme-font-secondary);
    font-size: .9rem;
  }

  .context-menu__box {
    padding: 12px 0;
    @media screen and (max-height: 450px) {
      overflow-x: auto;
      max-height: calc(100vh - 15vh - 65px);
    }
  }

  .context-menu-block__item {
    padding: 12px 15px;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: var(--theme-font-primary);

    svg {
      width: 24px;
      min-width: 24px;
      height: 24px;
      margin-right: 10px;
      color: var(--theme-font-tertiary);
    }

    &:hover {
      text-decoration: underline;
      color: var(--theme-font-primary);

      svg {
        color: var(--theme-font-primary);
      }
    }
  }
}
