@import "../../../../styles/variables/variables";
@import "../../../../styles/variables/breakpoints";

.custom-modal__details {
  dl:last-child {
    margin-bottom: 0;
  }

  @media screen and (max-height: 450px) {
    padding-top: 0;
    dt,
    dd {
      font-size: .85rem;
      margin-bottom: 0.125rem;
    }
  }
}
