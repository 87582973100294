@import "../../../styles/variables/variables";
@import "../../../styles/variables/breakpoints";

.mobile-menu-wrap {
  position: fixed;
  background: var(--theme-background);
  width: 80%;
  max-width: 315px;
  top: 0;
  bottom: 0;
  // left: 0;
  z-index: 3;
  left: -325px;


  .mobile-menu-container {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100%;
    padding: 23px 16px;

    -ms-overflow-style: none;  /* Hide scrollbar for IE and Edge */
    scrollbar-width: none;  /* Hide scrollbar for Firefox */
    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }
  }

  &--open {
    left: 0;
    transition: left .2s linear;

    .mobile-menu-container__close-btn {
      display: flex !important;
    }
  }

  .mobile-menu-container__close-btn {
    position: absolute;
    top: 0;
    right: -80px;
    width: 80px;
    height: 80px;
    display: none;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    svg {
      width: 40px;
      height: 40px;
      color: var(--theme-viewer-ui-el);
    }
  }

  .mobile-menu-container__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
  }
}

.mobile-menu-bg {
  position: fixed;
  background: rgba(0, 0, 0, 0.6);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  display: none;

  &--open {
    display: block;
  }
}
