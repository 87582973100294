@import "../../../styles/variables/variables";
@import "../../../styles/variables/breakpoints";

.file-form {
  .file-form__name-input {
    position: relative;

    input {
      padding-right: 4rem;
    }

    .file-form__format {
      position: absolute;
      right: 15px;
      top: calc(19px - .45rem);
      font-size: .9rem;
      line-height: .9rem;
      color: var(--theme-font-secondary);
    }
  }

  .file-form__format {
    position: absolute;
    right: 33px;
    top: calc(19px - .45rem);
    font-size: .9rem;
    line-height: .9rem;
    color: var(--theme-font-secondary);
  }
}
