@import "../../../styles/variables/variables";
@import "../../../styles/variables/breakpoints";

.settings-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 64px;

  .settings-header__wrap {
    display: flex;
    max-width: 830px;
    width: 100%;
  }

  .settings-header__avatar {
    margin-right: 2rem;
    flex-shrink: 0;

    @include breakpoint($lt-md) {
      margin-right: 1rem;
    }
  }

  .settings-header__info-box {
    flex-grow: 1;
    max-width: calc(100% - 128px - 2rem);
    color: var(--theme-font-primary);

    @include breakpoint($lt-md) {
      display: flex;
      flex-direction: column;
      justify-content: center;

      max-width: calc(100% - 80px - 1rem);
    }
  }

  .settings-header__quota {
    margin-top: .5rem;
  }

  .settings-header__quota-mobile {
    margin-top: .5rem;
    width: 100%;
    min-width: 100%;
  }

  .settings-header__name {
    font-weight: bold;
    font-size: 2rem;
    word-wrap: break-word;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @include breakpoint($lt-md) {
      font-size: 1.25rem;
    }
  }
}
