@import "../../styles/variables/variables";
@import "../../styles/variables/breakpoints";

.albums-screen {
  @include breakpoint($gt-sm) {
    min-height: calc(100vh - 155px - 74px); //высота headers
  }

  .albums-screen__wrap {
    padding-top: 30px;
    padding-left: $distance-between-sidebar-and-container;
    max-width: $max-container-width-with-sidebar + $distance-between-sidebar-and-container;

    @include breakpoint($lt-lg) {
      padding-left: 0;
    }
  }
}
