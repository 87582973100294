@import "../../styles/variables/variables";
@import "../../styles/variables/breakpoints";

.sharing-header {
  display: flex;
  height: 10vh;
  min-height: 60px;
  max-width: 1080px;
  width: 90%;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
  color: var(--theme-viewer-ui-el);

  @include breakpoint($lt-md) {
    flex-wrap: wrap;
    height: 15vh;

    &--only-logo {
      justify-content: space-around;
    }
  }

  .sharing-header__empty {
    display: none;
    width: 26px;
    height: 26px;

    @include breakpoint($lt-md) {
      display: block;
    }
  }

  .sharing-header__logo {
    width: 150px;
    height: 50px;

    img {
      width: 150px;
      height: 50px;
    }

    //@include breakpoint($lt-lg) {
    //  width: 68px;
    //  height: 24px;
    //  img {
    //    width: 68px;
    //    height: 24px;
    //  }
    //}
  }

  .sharing-header__name {
    text-align: center;
    padding: 0 0.5rem;

    max-width: 60%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @include breakpoint($lt-md) {
      order: 4;
      min-width: 100%;
    }

    @include breakpoint($lt-md) {
      font-size: .9rem;
    }
  }

  .sharing-header__download {
    width: 26px;
    height: 26px;
    cursor: pointer;
    color: var(--theme-viewer-ui-el);
    transition: color 100ms linear;

    svg {
      width: 100%;
      height: 100%;
    }

    &:hover {
      color: var(--theme-sharing-font-secondary);
    }
  }
}
