@import "../../../styles/variables/variables";
@import "../../../styles/variables/breakpoints";

.menu-item {
  display: flex;
  align-items: center;
  border-radius: $border-radius;
  background-color: var(--theme-button);
  color: var(--theme-font-primary);
  margin-bottom: 16px;
  padding: 12px 19px;
  cursor: pointer;

  &.active {
    background-color: var(--blue);
    color: var(--theme-always-white-font);

    &:hover {
      background-color: var(--blue);
      color: var(--theme-always-white-font);
    }
  }


  &:hover {
    background-color: var(--theme-button-hover);
    color: var(--theme-font-primary);
    text-decoration: none;
  }

  .menu-item-icon {
    width: 28px;
    min-width: 28px;
    height: 28px;
    min-height: 28px;
    margin-right: 16px;
  }

  .menu-item-img {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 3px;
  }

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
