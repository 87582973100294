@import "../../../../styles/variables/variables";
@import "../../../../styles/variables/breakpoints";

.select-device__item-info {
  color: var(--theme-font-secondary);
  font-size: .75rem;
}

.select-device__box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.select-device__container {
  width: calc(100% - 24px);
  max-width: calc(100% - 24px);
}

.select-device__active-status {
  width: 24px;
  height: 24px;
  background-image: url('/static/public/icons/check-blue.svg');
  background-repeat: no-repeat;
  background-size: contain;
}
