@import "../../../styles/variables/variables";
@import "../../../styles/variables/breakpoints";

.device-modal__header {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  @include breakpoint($lt-sm) {
    flex-direction: column;
  }
}

.device-modal__header-img {
  width: 50%;

  @include breakpoint($lt-sm) {
    width: 50%;
  }

  img {
    width: 100%;
  }
}

.device-modal__header-info {
  width: 50%;

  @include breakpoint($lt-sm) {
    width: 100%;
    text-align: center;
  }
  padding-left: 1rem;
  display: flex;
  flex-direction: column;

  h3 {
    color: var(--theme-font-primary);
    font-weight: bold;
    font-size: 1.5rem;
    margin-bottom: 0;
    line-height: 1.5;
  }

  .device-modal__primary-info {
    display: flex;
    flex-direction: column;
    color: var(--theme-font-secondary);
    margin-bottom: 15px;
  }
}

.device-modal__list {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;

  span:last-of-type {
    color: var(--theme-font-secondary);
  }
}

.device-modal__new_update {
  position: relative;
  background-color: var(--theme-select-background);
  padding: 9px 8px 9px 44px;
  font-weight: 600;
  font-size: .85rem;
  color: var(--blue);
  border-radius: $border-radius-big;
  margin-bottom: 15px;

  &:before {
    content: "";
    position: absolute;
    left: 10px;
    top: calc(50% - 12px);
    width: 24px;
    height: 24px;
    background-image: url("/static/public/icons/alert-blue.svg");
  }
}

.device-modal__desc {
  white-space: pre-wrap;
}

.device-modal__instruction {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  color: var(--blue);

  &:hover {
    text-decoration: underline;
    color: var(--blue);
  }

  .device-modal__instruction-icon {
    color: var(--blue);
    width: 28px;
    height: 28px;
    margin-right: 16px;
  }
}
