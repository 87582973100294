.def-layout {
  display: flex;
  padding: 0 15px;

  .def-layout__sidebar {
    flex-grow: 1;
    width:  $sidebar-width;
    min-width:  $sidebar-width;
    max-width:  $sidebar-width;
    margin-left: calc((100vw - #{$max-full-container-width} - 30px) / 2);

    @media (max-width: 1300px) {
      width: 24%;
      min-width: 24%;
      margin-left: 0;
    }

    &.def-layout__logo-sidebar {
      display: flex;
      align-items: center;
    }

    @include breakpoint($lt-lg) {
      display: none !important;
     }
  }

  .def-layout__content {
    width: 100%;

    @media (max-width: 1300px) {
      width: 76%;
    }
    @include breakpoint($lt-lg) {
      width: 100%;
    }
  }

  .def-layout__full-content {
    width: 100%;
    max-width: $max-full-container-width;
    margin: 0 auto;
  }
}

.def-layout-top {
  height: $header-height;

  @include breakpoint($lt-lg) {
    height: $header-height-mobile;
  }

  .def-layout__content {
    margin-left: $distance-between-sidebar-and-container;
    max-width: $max-container-width-with-sidebar;

    @include breakpoint($lt-lg) {
      margin-left: 0;
    }
  }
}

.unselect-container {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

