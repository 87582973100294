@import "../../../styles/variables/variables";
@import "../../../styles/variables/breakpoints";

.logo-wrap {
  display: block;
  width: $logo-width;

  img {
    width: $logo-width;
    height: $logo-height;
  }

  @include breakpoint($lt-md) {
    width: $logo-width-mobile;

    img {
      width: $logo-width-mobile;
      height: $logo-height-mobile;
    }
  }

  .top-header__black-logo {
    display: none;
  }
}

.light {
  .logo-wrap {
    .top-header__white-logo {
      display: none;
    }

    .top-header__black-logo {
      display: block;
    }
  }
}
