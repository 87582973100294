@import "../../../styles/variables/variables";
@import "../../../styles/variables/breakpoints";

.phone-login {
  .form-group {
    margin-bottom: 1.5rem;
  }

  .form-text {
    color: var(--theme-font-secondary);
  }

  .phone-login__submit-box {
    display: flex;
    justify-content: center;

    @include breakpoint($lt-md) {
      margin-top: 40px;
    }
  }

  .form-group--is-invalid {
    .flag-dropdown {
      border-color: #dc3545;
      border-right-color: #cacaca;
    }

    .invalid-feedback {
      display: block;
    }
  }

  .phone-login__rules {
    color: var(--theme-font-primary);
    margin-top: .5rem;
    //text-align: center;
    font-size: .8rem;
  }
}

.phone-login-modal {
  .modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 0;
    padding-bottom: 3rem;
  }

  .modal-header {
    border-bottom: none;
  }

  .action-timer {
    color: var(--theme-primary-color);
    margin-top: 1rem;
    transition: opacity .15s linear;

    .action-timer__link {
      text-decoration: underline;
      cursor: pointer;

      &:hover {
        color: var(--theme-primary-color-hover);
      }
    }

    &--disable {
      opacity: 0.6;

      .action-timer__link {
        cursor: default;
      }
    }
  }

  .invalid-feedback {
    display: block;
    text-align: center;
  }

  .btn {
    margin-top : 3rem;
  }

  .phone-login-modal__input {
    margin-top : 2rem;
    width: 100% !important;
    display: flex;
    justify-content: center;

    input {
      width: 38px !important;
      height: 38px !important;
      display: inline-block;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      padding: 0.375rem 0.125rem !important;
      font-size: 1rem;
      margin: 0 5px;
      font-weight: 400;
      line-height: 1.5;
      background-color: var(--theme-text-field);
      background-clip: padding-box;
      border: 1px solid var(--theme-text-field);
      border-radius: $border-radius-sm;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

      &:focus {
        background-color: var(--theme-text-field);
        //border-color: var(--theme-border);
        outline: 0;
        box-shadow: 0 0 0 0.2rem var(--theme-bp-input-shadow);

        + input {
          border: 1px solid var(--theme-text-field);
        }
      }

      &:first-child,
      &:last-child {
        border-color: var(--theme-text-field);
      }

      @include breakpoint($lt-sm) {
        width: 34px !important;
        height: 34px !important;
        padding: 0.35rem 0.7rem;
      }
    }

    &--error {
      input {
        border: 1px solid var(--theme-bp-input-border-warning) !important;

        &:focus {
          border: 1px solid var(--theme-bp-input-border-warning);
          box-shadow: 0 0 0 0.2rem var(--theme-bp-input-shadow-warning);

          + input {
            border: 1px solid var(--theme-bp-input-border-warning);
          }
        }
      }
    }
  }
}
