@import "../../../styles/variables/variables";
@import "../../../styles/variables/breakpoints";

.mobile-menu {
  @include breakpoint($gt-md) {
    display: none;
  }

  .mobile-menu__burger {
    cursor: pointer;
    width: 24px;
    height: 24px;
    color: var(--blue);
  }
}
