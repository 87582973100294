@import "../../../styles/variables/variables";
@import "../../../styles/variables/breakpoints";

.big-avatar {
  width: 128px;
  height: 128px;
  border-radius: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("/static/public/images/profile-round-dark.svg");

  &--with-decreasing {
    @include breakpoint($lt-md) {
      width: 80px;
      height: 80px;
    }
  }

  &--editable {
    background-image: url("/static/public/images/profile-camera-dark.svg");
  }
}

.light {
  .big-avatar {
    background-image: url("/static/public/images/profile-round-white.svg");

    &--editable {
      background-image:  url("/static/public/images/profile-camera.svg");
    }
  }
}
