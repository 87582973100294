@import "../../../styles/variables/variables";
@import "../../../styles/variables/breakpoints";

.download-manager-item {
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .download-manager-item__info {
    font-size: .9rem;
  }

  .download-manager-item__info-percent {
    margin-left: 10px;
    color: var(--theme-font-secondary);
    font-size: .8rem;
  }

  .download-manager-item__status-icon-box {
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 50%;
    border: 0.15rem solid var(--theme-font-secondary);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .download-manager-item__status-icon {
    width: 13px;
    height: 13px;
  }

  .download-manager-item__status-error {
    @extend .download-manager-item__status-icon-box;
    border-color: red;

    .download-manager-item__status-icon {
      color: red;
    }
  }

  .download-manager-item__status-success {
    @extend .download-manager-item__status-icon-box;
    border-color: green;

    .download-manager-item__status-icon {
      color: green;
    }
  }
}
