@import "../../../styles/variables/variables";
@import "../../../styles/variables/breakpoints";

//@use "sass:math";

.main-files-item_list {
  transition: opacity 0.1s linear;

  &.main-files-item--active {
    .main-files-item__wrap {
      background-color: var(--theme-select-background);
    }

    .main-files-item__icon {
      position: relative;
      transform: scale(.7);

      &:after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0, 130, 355, .4);
        background-image: url("/static/public/icons/ok-white.png");
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 40%;
        border-radius: $border-radius-sm;
      }
    }
  }

  .main-files-item__wrap {
    background-color: var(--theme-background);
    color: var(--theme-font-primary);
    cursor: pointer;
    padding: $default-padding;
    border-radius: $border-radius;
    margin-bottom: 8px;

    @include breakpoint($lt-md) {
      padding: calc(#{$default-padding} / 1.5);
      border-radius: calc(#{$border-radius} / 2);
    }

    display: flex;
    align-items: center;

    &--selected {
      background-color: var(--theme-select-background);
    }
  }

  .main-files-item__content {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .main-files-item__icon {
    width: $list-icon-width;
    min-width: $list-icon-width;
    height: $list-icon-height;
    margin-right: $list-col-padding;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: $border-radius-sm;

    @include breakpoint($lt-md) {
      border-radius: calc(#{$border-radius} / 2);
    }
    -webkit-transition: -webkit-transform .15s linear;
    -moz-transition: transform .15s linear;
    -o-transition: transform .15s linear;
    transition: transform .15s linear;
  }

  .main-files-item__info {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    width: calc(100% - #{$list-icon-width + $list-col-padding });
    max-width: calc(100% - #{$list-icon-width + $list-col-padding});

    @include breakpoint($lt-md) {
      flex-wrap: wrap;
    }
  }

  .main-files-item__title-text,
  .main-files-item__title,
  .main-files-item__info-meta-date,
  .main-files-item__info-meta-size {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .main-files-item__title {
    width: $list-name-col-width;
    min-width: $list-name-col-width;
    padding-right: $list-col-padding;
    display: flex;
    flex-direction: column;

    @include breakpoint($lt-md) {
      width: 100%;
    }
  }


  .main-files-item__title-text {
    line-height: 1.2;

    @include breakpoint($lt-md) {
      font-size: .95rem;
      line-height: 1.4;
    }
  }

  .main-files-item__title-public {
    color: var(--theme-font-secondary);
    font-size: .85rem;
  }

  .main-files-item__info-meta-date,
  .main-files-item__info-meta-size,
  .main-files-item__info-meta-public {
    @include breakpoint($lt-md) {
      font-size: .7rem;
      color: var(--theme-font-secondary);
    }
  }

  .main-files-item__info-meta-date {
    width: $list-meta-col-date;
    padding-right: $list-col-padding;

    @include breakpoint($lt-md) {
      width: auto;
      padding-right: 0;
    }
  }

  .main-files-item__info-meta-size {
    width: $list-meta-col-size;

    @include breakpoint($lt-md) {
      width: auto;

      &:before {
        content: '•';
        padding: 0 5px;
      }
    }
  }

  .main-files-item__info-meta-favorite {
    width: $list-name-col-favorite;

    @include breakpoint($lt-md) {
      display: none;
    }
  }

  .main-files-item__info-meta-public {
    &:before {
      content: '•';
      padding: 0 3px;
    }
  }

  .main-files-item__mobile-context {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 25px;
    height: 25px;
    top: calc(50% - 15px);
    right: 8px;

    svg {
      width: 80%;
      height: 80%;
      color: var(--theme-font-secondary);
    }
  }


  .main-files-item__favorite {
    display: none;
    width: 20px;
    height: 20px;
    padding-left: 1px;
    background-repeat: no-repeat;
    background-size: cover;
    transition: all .15s linear;
  }

  .main-files-item__content:hover {
    .main-files-item__favorite {
      display: block;
      background-image: url("/static/public/icons/favorite-outline.svg");

      &:hover {
        padding-left: 0;
        width: 22px;
        height: 22px;
      }
    }
  }

  &.main-files-item--favorite {
    .main-files-item__favorite {
      display: block;
      background-image: url("/static/public/icons/favorite.svg") !important;
    }
  }
}

.light .main-files-item_list {
  .main-files-item__content:hover {
    .main-files-item__favorite {
      background-image: url("/static/public/icons/favorite-black-outline.svg");
    }
  }

  &.main-files-item--favorite {
    .main-files-item__favorite {
      background-image: url("/static/public/icons/favorite-black.svg") !important;
    }
  }
}


.main-files-item_tile {
  margin-bottom: $custom-row-padding * 2;
  transition: opacity 0.1s linear;

  &.main-files-item--active {
    .main-files-item__wrap {
      background-color: var(--theme-select-background);
      border: 2px solid var(--theme-primary-color);
      transform: scale(0.95);
    }

    .main-files-item__icon:after {
      background:
        linear-gradient(0deg, rgba(0, 130, 255, 0.1), rgba(0, 130, 255, 0.1)),
        linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.85) 100%);
    }
  }

  .main-files-item__wrap {
    background-color: var(--theme-background);
    cursor: pointer;
    position: relative;
    border: 2px solid transparent;
    border-radius: $border-radius;
    overflow: hidden;

    -webkit-transition: -webkit-transform .15s linear;
    -moz-transition: transform .15s linear;
    -o-transition: transform .15s linear;
    transition: transform .15s linear;

    &--selected {
      background-color: var(--theme-select-background);
      border-color: var(--theme-primary-color);
    }

    &:before {
      content: "";
      padding-top: 75%;
      display: block;
    }
  }

  .main-files-item__content {
    position: absolute;
    top: -2px;
    bottom: -2px;
    left: -2px;
    right: -2px;
  }

  .main-files-item__mobile-context {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 25px;
    height: 25px;
    bottom: 3px;
    right: 0;

    svg {
      width: 80%;
      height: 80%;
      color: var(--theme-always-white-font);
    }
  }

  .main-files-item__icon {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.7) 100%);;
    }
  }

  .main-files-item__icon-skeleton {
    height: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .main-files-item__title {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 6px;
    color: var(--theme-always-white-font);
    display: flex;
    align-items: center;
    flex-wrap: nowrap;

    @include breakpoint($lt-md) {
      padding-right: 21px;
    }
  }

  .main-files-item__title-icon {
      width: 17px;
      min-width: 17px;
      height: 17px;
      color: var(--theme-always-white-font);
      margin-right: 5px;
  }

  .main-files-item__title-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: .8rem;
  }

  .main-files-item__favorite {
    display: none;
    position: absolute;
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-size: cover;
    right: 8px;
    top: 8px;
    transition:
      width .15s linear,
      height .15s linear,
      right .15s linear,
      top .15s linear;
  }

  .main-files-item__content:hover {
    .main-files-item__favorite {
      display: block;
      background-image: url("/static/public/icons/favorite-outline.svg");

      @include breakpoint($lt-lg) {
        display: none;
      }

      &:hover {
        width: 22px;
        height: 22px;
        right: 7px;
        top: 7px;
      }
    }
  }

  &.main-files-item--favorite {
    .main-files-item__favorite {
      display: block !important;
      background-image: url("/static/public/icons/favorite.svg") !important;
    }
  }
}

.main-files--without-ctx-btn {
  .main-files-item__mobile-context {
    display: none;
  }
}
