@import "../../styles/variables/variables";
@import "../../styles/variables/breakpoints";

.sharing-page {
  background-color: var(--theme-sharing-bg);

  width: 100%;
  height: 100%;
  min-height: 100vh;
  min-width: 100vw;
  color: white;
  padding-bottom: 1rem;

  .sharing-page__wrap {
    height: 85vh;
    max-width: 1080px;
    min-height: 300px;
    width: 90%;
    position: relative;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include breakpoint($lt-md) {
      height: 80vh;
    }

    &--empty {
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--theme-font-tertiary);
    }
  }

  .sharing-page__empty-icon {
    width: 176px;
    height: 176px;
    color: var(--theme-font-tertiary);
  }

  .sharing-page__placeholder {
    text-align: center;
    font-size: 3rem;
    font-weight: 200;
    margin-top: 1rem;

    @include breakpoint($lt-md) {
      font-size: 2rem;
      margin-top: .5rem;
    }
  }

  .sharing-page__spinner {
    padding-top: 7rem;
  }
}
