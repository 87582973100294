@import "../../../styles/variables/variables";
@import "../../../styles/variables/breakpoints";

.social-login {
  display: flex;
  justify-content: space-between;

  .social-login__item {
    background-color: var(--theme-login-buttons);
    flex-grow: 1;
    margin: 0 10px;
    border-radius: $border-radius-sm;
    height: 48px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color .15s linear;

    &:hover {
      background-color: var(--theme-login-buttons-hover);
    }

    &--fb {
      background-color: var(--theme-login-fb-btn-bg);

      &:hover {
        background-color: var(--theme-login-fb-btn-bg-hover);
      }
    }

    &:last-child {
      margin-right: 0;
    }

    &:first-child {
      margin-left: 0;
    }
  }
}
