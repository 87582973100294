@import "../../../../styles/variables/variables";
@import "../../../../styles/variables/breakpoints";

.add-file-to-album {
  display: flex;
  flex-wrap: wrap;
}

.add-file-to-album__body {
  height: 355px;
}

.add-file-to-album__search {
  margin-bottom: 1rem;
}
