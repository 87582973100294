@import "../../../styles/variables/variables";
@import "../../../styles/variables/breakpoints";

$add-file-to-album-item-margin: 12px;

.new-album-tile {
  margin-right: $add-file-to-album-item-margin;
  width: calc((100% - 2 * #{$add-file-to-album-item-margin}) / 3 );
  margin-bottom: 12px;
  cursor: pointer;

  &:hover {
    .new-album-tile__title {
      text-decoration: underline;
    }

    .new-album-tile__icon-box {
      transform: scale(1.05);
    }
  }

  @include breakpoint($lt-sm) {
    width: calc((100% - #{$add-file-to-album-item-margin}) / 2 );

    &:nth-child(3n) {
      margin-right: 0;
    }

    &:nth-child(2n) {
      margin-right: 0;
    }
  }

  .new-album-tile__icon-box {
    position: relative;
    border-radius: $border-radius;
    background-color: var(--theme-album-hints);
    transition: transform .3s;

    &:before {
      content: "";
      padding-top: 100%;
      display: block;
    }
  }

  .new-album-tile__icon {
    position: absolute;
    width: 60px;
    height: 60px;
    top: calc(50% - 30px);
    left: calc(50% - 30px);
    color: var(--blue);
  }

  .new-album-tile__title {
    padding-left: 8px;
    padding-top: 6px;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}