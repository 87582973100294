@import "../../../styles/variables/variables";
@import "../../../styles/variables/breakpoints";

.app-buttons {
  margin-top: 40px;
  margin-bottom: 15px;

  @include breakpoint($lt-md) {
    margin-top: 40px;
  }

  display: flex;
  justify-content: center;

  .app-buttons__btn {
    //background-color: var(--theme-button);
    width: 122px;
    height: 40px;
    border-radius: 4px;
    cursor: pointer;

    img {
      width: 100%;
    }

    +.app-buttons__btn {
      margin-left: 15px;
    }
  }
}
