@import "../../../../styles/variables/variables";
@import "../../../../styles/variables/breakpoints";

.select-with-search {
  position: relative;

  &--open {
    input {
      position: relative;
      z-index: 2;
    }

    .select-with-search__list-box {
      z-index: 2;
    }

    .select-with-search__bg {
      z-index: 1;
    }
  }

  .select-with-search__spinner {
    position: absolute;
    top: calc(50% - 8px);
    right: 10px;
    width: 16px;
    height: 16px;
    z-index: 3;
    padding: 0;
  }

  .select-with-search__list-box {
    margin-top: .25rem;
    position: relative;
    width: 100%;
    height: 0;
  }

  .select-with-search__list {
    position: absolute;
    top: 0;
    left: 0;
    max-height: 300px;
    width: 100%;
    overflow-y: scroll;
    z-index: 2;
    background-color: var(--theme-text-field);
    background-clip: padding-box;
    border-radius: 0.25rem;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      -webkit-border-radius: 4px;
      border-radius: 4px;
      background: var(--theme-button-hover);
    }

    &::-webkit-scrollbar-track {
      background-color: var(--theme-text-field);
    }
  }

  .select-with-search__item {
    color: var(--theme-font-primary);
    padding: 0.375rem 0.75rem;
    cursor: pointer;

    &:hover {
      background: var(--theme-border);
    }
  }

  .is-invalid + .select-with-search__spinner {
    display: none;
  }

  .select-with-search__bg {
    position: fixed;
    background-color: transparent;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
