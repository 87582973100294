// переопределение каких-то стилей bootstrap
.container {
  @media (min-width: 1200px) {
    max-width: 1288px;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    max-width: 1200px;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    max-width: 992px;
  }
}

.modal-content {
  background-color: var(--theme-modals-ui-element-bg);
  color: var(--theme-font-primary);
  border-radius: $border-radius;
  max-height: 90vh;
}

.modal-header {
  .close {
    color: var(--theme-font-tertiary);
    text-shadow: none;
    &:focus {
      outline: none;
    }
  }
}

.modal-body {
  overflow-x: auto;
}

.row {
  &.custom-row {
    margin-right: - $custom-row-padding;
    margin-left: - $custom-row-padding;

    .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8,
    .col-9, .col-10, .col-11, .col-12, .col, .col-auto,
    .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5,
    .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10,
    .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1,
    .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7,
    .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
    .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5,
    .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11,
    .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3,
    .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9,
    .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto {
      padding-left: $custom-row-padding;
      padding-right: $custom-row-padding;
    }
  }
}

.form-control {
  background-color: var(--theme-text-field);
  border-color: var(--theme-text-field);
  color: var(--theme-font-primary);
  border-radius: $border-radius-sm;

  transition: background-color 0.1s linear, border-color 0.1s linear, box-shadow 0.1s linear;

  &:focus {
    background-color: var(--theme-text-field);
    border-color: var(--theme-text-field);
    color: var(--theme-font-primary);
  }

  &.is-invalid {
    background-image: none;
  }
}

.form-text {
  &:after { // чтобы форма не прыгала, при ошибках валидации
    content: '-';
    opacity: 0;
    margin-right: -5px;
  }
}
