@import "../../../styles/variables/variables";
@import "../../../styles/variables/breakpoints";

.download-manager {
  width: 300px;

  position: fixed;
  bottom: 15px;
  right: 15px;
  color: var(--theme-font-primary);

  @include breakpoint($lt-sm) {
    left: 10px;
    right: 10px;
    bottom: 10px;
    width: calc(100% - 20px);
  }

  .download-manager__header {
    background-color: var(--theme-border);
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    min-height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    font-size: .9rem;
  }

  .download-manager__header-icon {
    width: 18px;
    height: 18px;
    cursor: pointer;
    color: var(--theme-font-secondary);
    transition: all .3s linear;

    +.download-manager__header-icon {
      margin-left: 8px;
    }

    &:hover {
      color: var(--theme-font-primary);
    }
  }

  .download-manager__list {
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    border: 1px solid var(--theme-border);
    background-color: var(--theme-button);
  }

  &--hidden {
    .download-manager__list {
      height: 0;
      border: none;
    }

    .download-manager__header {
      border-radius: $border-radius;
    }

    .download-manager__icon-hidden {
      transform: rotate(180deg) !important;
    }
  }
}
