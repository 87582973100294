@import "../../../styles/variables/variables";
@import "../../../styles/variables/breakpoints";

$connected-account-item-height: 38px;

.connected-account-item {
  margin-bottom: 8px;
  height: $connected-account-item-height;
  display: flex;
}

.connected-account-item__icon {
  height: 100%;
  width: $connected-account-item-height;
  margin-right: 8px;
  background-color: var(--theme-text-field);
  border-radius: $border-radius;
  display: flex;
  justify-content: center;
  align-items: center;
}

.connected-account-item__name {
  width: calc(100% - #{$connected-account-item-height} - 8px);
  max-width: calc(100% - #{$connected-account-item-height} - 8px);
  height: 100%;
  background-color: var(--theme-text-field);
  border-radius: $border-radius;
  color: var(--theme-font-secondary);
  padding: 0 16px;
  display: flex;
  align-items: center;
  span {
    display: block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
