@import "../../../../styles/variables/variables";
@import "../../../../styles/variables/breakpoints";

.custom-modal-languages {
  .custom-modal-languages__search {
    margin-bottom: 1rem;
  }

  .custom-modal-languages__box {
    height: 230px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 8px;
    }


    &::-webkit-scrollbar-thumb {
      -webkit-border-radius: 4px;
      border-radius: 4px;
      background: var(--theme-button-hover);
    }
  }

  .custom-modal-languages__item {
    padding: 10px;
    border-radius: $border-radius;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:hover {
      background-color: var(--theme-text-field);
    }
  }
}
