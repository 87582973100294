@import "../../styles/variables/variables";
@import "../../styles/variables/breakpoints";

.support-page {
  width: 100%;
  min-height: 100vh;
  background-color: var(--theme-background);
  background-image: url(/static/public/images/bg/login-bg-0.png);
  background-size: cover;
  background-position: top center;

  .support-page__container {
    color: var(--theme-font-primary);
    padding: 0 140px;

    @include breakpoint($lt-xl) {
      padding: 0;
    }
  }

  .support-page__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    h1 {
      font-weight: 700;
      font-size: 2.15rem;
      text-align: center;

      @include breakpoint($lt-md) {
        font-size: 1.8rem;
      }
    }

    p {
      font-size: 1.25rem;
      text-align: center;

      @include breakpoint($lt-md) {
        font-size: 1.1rem;
      }
    }
  }

  .support-page__form {
    margin-top: 3rem;

    @include breakpoint($lt-md) {
      margin-top: 1.5rem;
    }

    label {
      color: var(--theme-font-primary)
    }

    .form-group {
      margin-bottom: 1.5rem;
    }
  }

  .support-form__btn-box {
    margin: 2rem 0;
    display: flex;
    justify-content: center;
  }

  .support-page__btn-box {
    display: flex;
    justify-content: center;
  }
}
