@import "../../styles/variables/variables";
@import "../../styles/variables/breakpoints";

@mixin currentBg($step, $random) {
  @if $step == 0 {
    background-image: url('/static/public/images/bg/login-bg-#{$random}.png');
  } @else if $random + $step <= 4 {
    background-image: url('/static/public/images/bg/login-bg-#{$random + $step}.png');
  } @else {
    background-image: url('/static/public/images/bg/login-bg-#{$random + $step - 5}.png');
  }
}

@mixin currentAnimation($random) {
  @keyframes headerLane#{$random} {
    0% {
      @include currentBg($random, 0);
    }
    15% {
      @include currentBg($random, 0);
    }
    20% {
      @include currentBg($random, 1);
    }
    35% {
      @include currentBg($random, 1);
    }
    40% {
      @include currentBg($random, 2);
    }
    55% {
      @include currentBg($random, 2);
    }
    60% {
      @include currentBg($random, 3);
    }
    75% {
      @include currentBg($random, 3);
    }
    80% {
      @include currentBg($random, 4);
    }
    95% {
      @include currentBg($random, 4);
    }
    100% {
      @include currentBg($random, 0);
    }
  }

  animation-name: headerLane#{$random};
}

.login-page {
  display: flex;
  flex-direction: column;
  //justify-content: center;
  //align-items: center;
  width: 100%;
  height: 100vh;
  background-color: var(--theme-background);
  background-image: url(/static/public/images/bg/login-bg-0.png);
  background-size: cover;
  background-position: top center;
  animation-duration: 25s;
  animation-iteration-count: infinite;

  @include currentAnimation(0);

  &[data-bg='1']{
    @include currentAnimation(1);
  }
  &[data-bg='2']{
    @include currentAnimation(2);
  }
  &[data-bg='3']{
    @include currentAnimation(3);
  }
  &[data-bg='4']{
    @include currentAnimation(4);
  }

  h1 {
    color: var(--theme-font-primary);
    font-size: 2rem;
    margin-bottom: 1.5rem;

    @include breakpoint($lt-md) {
      font-size: 1.3rem;
    }
  }

  @media screen and (min-height: 0) and (max-height: 755px) {
    height: 755px;
  }

  .login-page__main-box {
    flex-grow: 2;
    display: flex;
    justify-content: center;
    align-items: center;

    @include breakpoint($lt-lg) {
      flex-direction: column;
    }
  }

  .login-page__logo {
    margin-left: 30px;
    margin-top: -95px;

    max-width: 585px;
    width: 40vw;

    .login-page__logo-black {
      display: none;
    }

    @include breakpoint($lt-lg) {
      margin-bottom: 100px;

      max-width: 360px;
      width: 60vw;
      margin-left: 0;
      margin-top: 0;
    }

    @include breakpoint($lt-md) {
      margin-bottom: 50px;
    }

    img {
      width: 100%;
    }

    @keyframes logoAnimation {
      from { transform: scale(1) }
      50% { transform: scale(.85) }
      to { transform: scale(1) }
    }

    @keyframes logoAnimationMobile {
      from { transform: scale(1) }
      50% { transform: scale(.9) }
      to { transform: scale(1) }
    }

    &--loading {
      animation: logoAnimation 1.8s ease-in-out infinite;

      @include breakpoint($lt-sm) {
        animation: logoAnimationMobile 1.8s ease-in-out infinite;
      }
    }
  }

  .login-page__box {
    width: 400px;
    margin-left: 150px;
    margin-right: 30px;

    transition: width .3s,
                margin-left .3s,
                margin-right .3s,
                opacity .1s .3s;

    @include breakpoint($lt-xl) {
      margin-left: 100px;
    }

    @include breakpoint($lt-lg) {
      margin: 0;
    }

    @include breakpoint($lt-sm) {
      width: 90vw;
    }

    &--hidden {
      visibility: hidden;

      @include breakpoint($gt-md) {
        width: 0;
        margin-left: 0;
        margin-right: 0;
        opacity: 0;
      }
    }
  }

  .login-page__delimiter {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;

    &:before,
    &:after {
      width: 45%;
      content: '';
      height: 1px;
      display: block;
      flex-grow: 1;
      background-color: var(--theme-font-primary);
      margin-top: 3px;
    }
  }

  .login-page__delimiter-text {
    color: var(--theme-font-primary);
    line-height: 1;
    padding: 0 15px;
    border-radius: 10px;
  }

  .login-page__links {
    padding-top: 1rem;
    display: flex;
    justify-content: center;
    //font-size: .9rem;
    //line-height: 1;

    //@include breakpoint($lt-md) {
    //  font-size: .7rem;
    //}

    a {
      position: relative;
      color: var(--theme-font-primary);
      padding: 0 15px;

      @include breakpoint($lt-md) {
        padding: 0 10px;
      }

      &:hover {
        color: var(--theme-font-primary);
      }

      + a:before {
        content: '•';
        display: block;
        width: .9rem;
        height: .9rem;
        position: absolute;
        top: calc(50% - .45rem);
        left: -.45rem;
        text-align: center;

        @include breakpoint($lt-md) {
          width: .7rem;
          height: .7rem;
          top: calc(50% - .35rem);
          left: -.35rem;
        }
      }
    }
  }
}

.light {
  .login-page .login-page__logo {
    .login-page__logo-white {
      display: none;
    }

    .login-page__logo-black {
      display: block;
    }
  }
}
