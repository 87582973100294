@import "../../styles/variables/variables";
@import "../../styles/variables/breakpoints";

.top-header {
  height: 100%;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;

  &--hidden-search {
    @include breakpoint($gt-md) {
      justify-content: flex-end;
    }
  }

  .top-header__right-box {
    display: flex;
    align-items: center;
  }

  .top-header__profile-box {
    display: flex;
    align-items: center;
  }

  .top-header__profile-name {
    max-width: 230px;
    font-weight: bold;
    font-size: 1.4rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 24px;
    color: var(--theme-font-primary);

    &:hover {
      text-decoration: none;
    }
  }

  .top-header__profile-avatar-box {
    margin-left: 25px;

    .top-header__profile-avatar {
      width: 48px;
      height: 48px;
      border-radius: $border-radius;
      background-size: cover;
      background-repeat: no-repeat;
      cursor: pointer;
      background-position: center;
      background-image: url("/static/public/images/profile-img-dark.svg");
    }
  }

  &--only-logo .container {

    .top-search,
    .top-header__profile-avatar-box {
      display: none;
    }
  }
}

.light {
  .top-header {
    .top-header__profile-avatar-box .top-header__profile-avatar {
      background-image: url("/static/public/images/profile-img.svg");
    }
  }
}
