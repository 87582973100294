/** Размеры экранов для адаптива **/
/** В первую очередь используются для breakpoints **/
$xs-var: 0px  !default;
$sm-var: 576px !default;
$md-var: 768px !default;
$lg-var: 992px !default;
$xl-var: 1200px !default;

$border-radius-sm: 8px !default;
$border-radius: 12px !default;
$border-radius-big: 0.5rem !default;

$default-padding: 15px !default;
$custom-row-padding: 3px !default;

$logo-width: 170px !default;
$logo-height: 60px !default;

$logo-width-mobile: 114px !default;
$logo-height-mobile: 40px !default;

$header-height: 148px !default;
$header-height-mobile: 88px !default;
$header-bottom-margin: 15px !default;

/* layout variables */
$sidebar-width: 268px !default;
$sidebar-width-lt-xl: 200px !default;
$max-container-width-with-sidebar: 934px !default;
$distance-between-sidebar-and-container: 56px !default;
$max-full-container-width: $distance-between-sidebar-and-container + $max-container-width-with-sidebar + $sidebar-width !default;

//1258  s 21.3  78.7
/* END layout variables */

$list-icon-width: 65px !default;
$list-icon-height: 40px !default;
$list-col-padding: 15px !default;
$list-icon-width--mobile: 48px !default;
$list-icon-height--mobile: 30px !default;
$list-col-padding--mobile: 10px !default;

$list-name-col-width: 54% !default;
$list-name-meta-box-width: 40% !default;

$list-meta-col-date: 24% !default;
$list-meta-col-size: 16% !default;
$list-name-col-favorite: 6% !default;

$ui-placeholder-color: #e1e1e6 !default;
