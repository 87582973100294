@import "../../../../styles/variables/variables";
@import "../../../../styles/variables/breakpoints";

.custom-modal-delete {
  .custom-modal-delete__input-label {
    margin-top: 2rem;
    margin-bottom: .25rem;
    color: var(--theme-font-secondary);
  }
}
